/* eslint-disable react/react-in-jsx-scope */
// import * as React from "react";
import { css } from "@emotion/react";

export default function Menubar() {
  return (
    <div
      css={css`
        padding: 5px;
        font-size: 12px;
        width: 100%;
        background-color: #e9e9e9;
        border: 1px solid black;
      `}
    >
      ex0 v0.0.2
    </div>
  );
}
