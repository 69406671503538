import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import WindowManager, { DelegatedWindowProps } from "./WindowManager";
import Term from "./apps/terminal";
import Clock from "./apps/clock";
import Root from "./components/Root";
import Menubar from "./components/Menubar";
import { useStandardRegistry } from "./appRegistry";
import { getStandardEnv } from "./environment";
import { getFileSystem } from "./fileSystem";
import Raph from "./apps/raph";
import "./main.scss";

const App = () => {
  let environment = getStandardEnv();
  const fileSystem = getFileSystem();
  const registry = useStandardRegistry(fileSystem);

  const viewRegistry = {
    Clock: () => <Clock />,
    Raph: () => <Raph />,

    Term: () => (
      <Term
        onCommand={async ({ command, terminal }) => {
          const [commandName, ...args] = command.split(" ");
          try {
            const { newEnvironment, windows: newWindows } = await registry.exec(
              commandName,
              {
                terminal: terminal,
                environment,
                args,
              },
            );
            if (newEnvironment) environment = newEnvironment;
            if (newWindows) {
              document.dispatchEvent(
                new CustomEvent<DelegatedWindowProps[]>("hello", {
                  detail: newWindows,
                }),
              );
            }
          } catch (e) {
            terminal.write("\r\nerror: " + e);
          }
        }}
      />
    ),
  };

  registry.registerCommand({
    name: "clock",
    exec: async ({ terminal }) => {
      terminal.write("\r\n starting clock");
      return {
        windows: [
          {
            startPos: { x: 740, y: 260 },
            title: `Clock12`,
            width: 210,
            height: 210,
            view: "Clock",
          },
        ],
      };
    },
  });

  registry.registerCommand({
    name: "raph",
    exec: async ({ terminal }) => {
      terminal.write("\r\n starting raph");
      return {
        windows: [
          {
            startPos: { x: 740, y: 20 },
            title: `Raph`,
            width: 300,
            height: 220,
            view: "Raph",
          },
        ],
      };
    },
  });

  registry.registerCommand({
    name: "term",
    exec: async ({ terminal }) => {
      terminal.write("\r\n starting terminal");
      return {
        windows: [
          {
            startPos: { x: 20, y: 20 },
            title: `Terminal`,
            width: 700,
            height: 400,
            view: "Term",
          },
        ],
      };
    },
  });

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent<DelegatedWindowProps[]>("hello", {
        detail: [
          {
            startPos: { x: 20, y: 20 },
            title: `Terminal`,
            width: 700,
            height: 400,
            view: "Term",
          },
        ],
      }),
    );
  }, []);

  return (
    <Root>
      <Menubar />
      <WindowManager viewRegistry={viewRegistry} />
    </Root>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
