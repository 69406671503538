import * as BrowserFS from "browserfs";
import Stats from "browserfs/dist/node/core/node_fs_stats";
import promisify from "util.promisify";

BrowserFS.install(window);
// Configures BrowserFS to use the LocalStorage file system.
window.BrowserFS = BrowserFS;
BrowserFS.configure(
  {
    fs: "MountableFileSystem",
    options: {
      "/tmp": { fs: "InMemory" },
      "/home": { fs: "LocalStorage" },
    },
  },
  (e) => {
    if (e) {
      console.error("filesystem errored", e);
    } else {
      console.log("filesystem started");
    }
  }
);

export type FileSystem = {
  writeFile: (filename: string, contents: string) => Promise<Buffer>;
  readdir: (filename: string) => Promise<string[]>;
  readFile: (filename: string) => Promise<Buffer>;
  mkdir: (path: string) => Promise<void>;
  stat: (path: string) => Promise<Stats>;
};

export const getFileSystem = (): FileSystem => {
  const fs = BrowserFS.BFSRequire("fs");

  return {
    writeFile: promisify(fs.writeFile),
    readFile: promisify(fs.readFile),
    readdir: promisify(fs.readdir),
    mkdir: promisify(fs.mkdir),
    stat: promisify(fs.stat),
  };
};
