import * as React from "react";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";

export interface WindowProps {
  title?: string;
  onActive?: () => void;
  onClickClose?: () => void;
  width: number;
  height: number;
  active?: boolean;
  zIndex?: number;
  contents: React.ReactNode;
  startPos?: {
    x?: number;
    y?: number;
  };
}

export default function Window({
  contents: Component,
  title,
  width,
  height,
  startPos,
  zIndex,
  active,
  onActive,
  onClickClose,
}: React.PropsWithChildren<WindowProps>) {
  return (
    <Draggable onMouseDown={onActive} bounds="parent" handle=".title">
      <div
        style={{
          zIndex,
          top: startPos?.y,
          left: startPos?.x,
          position: "absolute",
        }}
      >
        <ResizableBox width={width} height={height}>
          <div
            style={
              {
                // width: 300,
                // height: 200,
                // position: "absolute",
              }
            }
            className={`window ${active ? "active" : ""}`}
          >
            <div className="menuBar">
              <div className="leftButtons">
                <button className="closeWidget" onClick={onClickClose} />
              </div>
              <div className="title">{title || "Untitled Window"}</div>
              <div className="rightButtons empty">
                {/* <button className="closeWidget" />
              <button className="closeWidget" /> */}
              </div>
            </div>
            <div className="contents">
              <div className="in">{Component}</div>
            </div>
          </div>
        </ResizableBox>
      </div>
    </Draggable>

    //   </Rnd>
  );
}
