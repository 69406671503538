import React, { useEffect } from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "../../../node_modules/xterm/css/xterm.css";
// import ansiEscapes from "ansi-escapes";

const fitAddon = new FitAddon();
// window.fitAdon = fitAddon;

type Props = {
  onCommand: (props: { command: string; terminal: Terminal }) => Promise<void>;
};

function attachTerminal(
  element: HTMLElement,
  onCommand: (props: { command: string; terminal: Terminal }) => Promise<void>,
) {
  const prompt = () => term.write("\r\n$ ");

  const term = new Terminal({
    convertEol: true,
    fontFamily: `'Fira Mono', monospace`,
    fontSize: 15,
    fontWeight: 400,
  });

  //Styling
  term.setOption("theme", {
    background: "black",
    foreground: "white",
  });

  // Load Fit Addon
  term.loadAddon(fitAddon);

  // Open the terminal in #terminal-container
  term.open(element);

  //Write text inside the terminal
  term.write("Welcome to RaphShell v0.1!");

  // Make the terminal's size and geometry fit the size of #terminal-container
  fitAddon.fit();

  let command = "";

  term.onKey(async (key) => {
    const char = key.domEvent.key;

    switch (char) {
      case "ArrowLeft":
        // term.write(ansiEscapes.cursorBackward());
        break;
      case "ArrowRight":
        // term.write(ansiEscapes.cursorForward());
        break;
      case "Backspace":
        if (!!command) {
          command = command.slice(0, -1);
          term.write("\b \b");
        }
        break;
      case "Enter":
        if (!!command.trim()) {
          await onCommand({
            command,
            terminal: term,
          });
        }
        command = "";
        prompt();
        break;
      default:
        command += char;
        term.write(char);
    }
  });

  prompt();
}

export default function Term({ onCommand }: Props) {
  useEffect(() => {
    attachTerminal(document.getElementById("xterm"), onCommand);
  }, []);

  return (
    <div
      id="xterm"
      style={{
        width: "100%",
        backgroundColor: "#222",
        flexGrow: 1,
      }}
    />
  );
}
