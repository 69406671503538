import { css } from "@emotion/react";
import * as React from "react";

export default function Clock() {
  React.useEffect(() => {
    const hour_hand = document.getElementById("hour_hand");
    const min_hand = document.getElementById("min_hand");
    const sec_hand = document.getElementById("sec_hand");
    const segments = document.getElementById("segments");
    let num_rotation = 0;
    let seg_rotation = 0;

    for (let idx = 1; idx <= 60; idx++) {
      const segment = document.createElement("div");
      const mark = document.createElement("div");
      segment.classList.add("segment");
      segment.style.transform = `rotate(${(seg_rotation += 6)}deg) translate(-50%, -100%)`;
      mark.classList.add("mark");
      if (idx % 5 === 0) {
        const num = document.createElement("span");
        num.innerText = idx / 5;
        num.classList.add("num");
        num.style.transform = `translateX(-50%) rotate(-${(num_rotation += 30)}deg)`;
        mark.classList.add("mark-hour");
        segment.appendChild(mark);
        segment.appendChild(num);
      } else {
        segment.appendChild(mark);
      }
      segments.appendChild(segment);
    }

    (function getTime() {
      const time = new Date();
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      const hour_pos = (hours * 360) / 12 + (minutes * 360) / 60 / 12;
      const min_pos = (minutes * 360) / 60 + (seconds * 6) / 60;
      const sec_pos = (seconds * 360) / 60;
      hour_hand.style.transform = `rotate(${hour_pos}deg) translateY(-3rem)`;
      min_hand.style.transform = `rotate(${min_pos}deg) translateY(-4.5rem)`;
      sec_hand.style.transform = `rotate(${sec_pos}deg) translateY(-4rem)`;
      setTimeout(getTime, 1000);
    })();
  });
  return (
    <div
      css={css`
        background-color: #c0c0c0;
        width: "100%";
        flex-grow: 1;

        .outer {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          height: 26rem;
          width: 26rem;
          border-radius: 50%;
          // border: 10px solid black;
          /* 	box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.5); */
        }

        .inner {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 26rem;
          width: 26rem;
          border-radius: 50%;
          border: 4px solid black;
          transform: scale(0.4); /* Equal to scaleX(0.7) scaleY(0.7) */
          background-color: white;
          /* 	background: radial-gradient(circle, #ffffff 45%, #c4c4c4 80%); */
        }

        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          width: 1.5rem;
          background: #0b0b0b;
          border-radius: 50%;
        }

        .center-second {
          background: red;
          height: 0.8rem;
          width: 0.8rem;
        }

        .second-hand {
          height: 10rem;
          width: 0.1rem;
          background: red;
          transform: translateY(-4rem);
        }

        .minute-hand {
          height: 0;
          width: 1rem;
          border-bottom: 9rem solid black;
          border-right: 0.4rem solid transparent;
          border-left: 0.4rem solid transparent;
          transform: translateY(-4.5rem);
        }

        .hour-hand {
          height: 0;
          width: 1rem;
          border-bottom: 10rem solid black;
          border-right: 0.4rem solid transparent;
          border-left: 0.4rem solid transparent;
          transform: translateY(-3rem);
        }

        .segments {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -100%);
        }

        .segment {
          position: absolute;
          top: 0;
          left: 0;
          height: 12.8rem;
          width: 0px;
          background: green;
          transform-origin: center top;
        }

        .mark {
          height: 1.2rem;
          width: 1px;
          background: black;
        }

        .mark-hour {
          height: 1.2rem;
          transform: translateX(-2px);
          width: 4px;
        }

        .num {
          position: absolute;
          top: 2.7rem;
          left: 50%;
          text-align: center;
          line-height: 0;
          font-size: 2rem;
          font-weight: 700;
          font-family: helvetica;
        }
      `}
    >
      <div className="outer">
        <div className="inner">
          <div className="center">
            <div className="hour-hand" id="hour_hand"></div>
          </div>
          <div className="center">
            <div className="minute-hand" id="min_hand"></div>
          </div>
          <div className="center center-second">
            <div className="second-hand" id="sec_hand"></div>
          </div>
          <div className="segments" id="segments"></div>
        </div>
      </div>
    </div>
  );
}
