import React from "react";

import { Terminal } from "xterm";
import { Environment } from "./environment";
import path from "path";
import { FileSystem } from "./fileSystem";
import colors from "ansi-colors";
import { DelegatedWindowProps } from "./WindowManager";

type ExecParams = {
  terminal: Terminal;
  environment: Environment;
  args?: string[];
};

type ExecResult = {
  newEnvironment?: Environment;
  windows?: DelegatedWindowProps[];
};

type Command = {
  name: string;
  exec: (params: ExecParams) => Promise<ExecResult>;
};

type Registry = {
  help: () => string[];
  registerCommand: (command: Command) => void;
  exec: (name: string, params: ExecParams) => Promise<ExecResult>;
};

function useCommandRegistry(): Registry {
  const commands: Record<string, Command> = {};
  return {
    help: () => Object.values(commands).map((c) => `${c.name}`),
    registerCommand: (command: Command) => {
      commands[command.name] = command;
    },
    exec: async (name: string, params: ExecParams) => {
      if (commands[name]) {
        return commands[name].exec(params);
      } else {
        throw `Unknown commend: "${name}"`;
      }
    },
  };
}

export function useStandardRegistry(fs: FileSystem): Registry {
  const { registerCommand, exec, help } = useCommandRegistry();

  registerCommand({
    name: "ls",
    exec: async ({ terminal, environment }) => {
      const contents = await fs.readdir(environment.pwd);

      contents.sort().forEach(async (file) => {
        if ((await fs.stat(path.join(environment.pwd, file))).isDirectory()) {
          terminal.write("\r\n" + colors.cyan(file));
        } else {
          terminal.write("\r\n" + file);
        }
      });
      return {};
    },
  });

  registerCommand({
    name: "mkdir",
    exec: async ({ args, environment }) => {
      await fs.mkdir(path.join(environment.pwd, args[0]));
      return {};
    },
  });

  registerCommand({
    name: "write",
    exec: async ({ args, environment }) => {
      const [filename, ...contents] = args;
      await fs.writeFile(
        path.join(environment.pwd, filename),
        contents.join(" "),
      );
      return {};
    },
  });

  registerCommand({
    name: "stat",
    exec: async ({ args, environment, terminal }) => {
      const res = await fs.stat(path.join(environment.pwd, args[0]));
      console.log(res);
      terminal.write("\r\n" + JSON.stringify(res, null, 2));

      return {};
    },
  });

  registerCommand({
    name: "read",
    exec: async ({ args, environment, terminal }) => {
      const contents = await fs.readFile(path.join(environment.pwd, args[0]));
      terminal.write("\r\n" + contents.toString());
      return {};
    },
  });

  registerCommand({
    name: "cd",
    exec: async ({ args, environment, terminal }) => {
      const res = await fs.stat(path.join(environment.pwd, args[0]));
      if (!res.isDirectory()) {
        terminal.write(`\r\n${args[0]} is not a directory`);
        return {};
      }
      return {
        newEnvironment: {
          ...environment,
          pwd: path.join(environment.pwd, args[0]),
        },
      };
    },
  });

  registerCommand({
    name: "pwd",
    exec: async ({ terminal, environment }) => {
      terminal.write("\r\n" + environment.pwd);
      return {};
    },
  });

  registerCommand({
    name: "help",
    exec: async ({ terminal }) => {
      help().forEach((file) => {
        terminal.write("\r\n" + file);
      });
      return {};
    },
  });

  return { exec, registerCommand, help };
}
