import React from "react";
import image from "./raph.jpeg";

export default function () {
  return (
    <div
      id="raph"
      style={{
        width: "100%",
        backgroundColor: "red",
        flexGrow: 1,
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
        }}
        src={image}
      />
    </div>
  );
}
