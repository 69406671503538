import * as React from "react";
import { useState } from "react";
import Window, { WindowProps } from "./Window";

export type DelegatedWindowProps = Omit<WindowProps, "contents"> & {
  view: string;
};

type Props = {
  viewRegistry: any;
};

export default function WindowManager({ viewRegistry }: Props) {
  const [windows, setWindows] = useState<DelegatedWindowProps[]>([]);
  const [maxZIndex, setMaxZIndex] = useState<number>(1);

  function setActiveWindow(
    windows: DelegatedWindowProps[],
    activeIndex: number,
  ) {
    return windows.map((w, j) =>
      j === activeIndex
        ? { ...w, zIndex: maxZIndex + 1, active: true }
        : { ...w, active: false },
    );
  }

  React.useEffect(() => {
    function handleStatusChange(e: CustomEvent<DelegatedWindowProps[]>) {
      setWindows(
        setActiveWindow(
          [
            ...windows,
            ...e.detail.map((w, i) => ({ ...w, zIndex: maxZIndex + i + 1 })),
          ],
          windows.length + e.detail.length - 1,
        ),
      );
      setMaxZIndex(maxZIndex + e.detail.length);
    }
    document.addEventListener("hello", handleStatusChange);
    return function cleanup() {
      document.removeEventListener("hello", handleStatusChange);
    };
  });

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      {windows.map((w, i) => (
        <Window
          {...w}
          key={i}
          zIndex={w.zIndex}
          active={w.active}
          contents={viewRegistry[w.view]?.()}
          onClickClose={() => {
            setWindows(windows.filter((_, j) => i !== j));
          }}
          onActive={() => {
            setWindows(setActiveWindow(windows, i));
            setMaxZIndex(maxZIndex + 1);
          }}
        />
      ))}
    </div>
  );
}
