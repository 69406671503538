import * as React from "react";

export default ({ children }: React.PropsWithChildren<any>) => (
  <div
    style={{
      display: "flex",
      flexFlow: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}
  >
    {children}
  </div>
);
